import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './adminAnalyticsActions'
import * as views from './adminAnalyticsViews'
import Location from "../../models/location/Location";

const AdminAnalyticsStore = types
  .model('AdminAnalyticsStore', {
    locations: types.maybeNull(types.optional(
      types.array(types.reference(Location)), []
    )),
    loading: types.optional(types.boolean, false)
  })
  .views(views.adminAnalyticsViews)
  .actions(actions.adminAnalyticsActions)
  .actions(self => ({
    set(key, value) {
      self[key] = value
    }
  }))

export default AdminAnalyticsStore
