import axios from "axios"
import { _throw } from "../utils/error-service"
import { API, BASIC_AUTH } from '../constants/system'
import { ORDER_STATUS } from "../constants/types"

export const api_update_order = async (data, token, orderId) => {
  try {
    const res = await axios.put(`${API}/cms/order/${orderId}`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_delete_order = async (token, orderId) => {
  try {
    const res = await axios.put(`${API}/cms/cancel-order/${orderId}`, {}, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_order_by_id = async (id, token) => {
  try {
    const res = await axios.get(`${API}/cms/order/${id}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_orders_by_merchant_id_paginate = async (token, page, row, merchantId, searchQuerry, status) => {
  try {
    const res = await axios.get(`${API}/cms/orders/${merchantId}?page=${page}&items=${row}&status=${status}&query=${searchQuerry}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_orders_on_budz_paginate = async (token, page, row, searchQuerry, status) => {
  try {
    const res = await axios.get(`${API}/cms/orders?page=${page}&items=${row}&status=${status}&query=${searchQuerry}`, {
      headers: {
        'Authorization': token
      }
    })
    return res.data
  } catch (err) {
    return _throw(err)
  }
}

export const api_post_create_order = async (data, token) => {
  try {
    const res = await axios.post(`${API}/cms/order`, data, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_accept_order = async (token, orderId) => {
  try {
    const res = await axios.put(`${API}/cms/order-status/${orderId}`, {
      status: "ORDERED"
    }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_complete_order = async (token, orderId) => {
  try {
    const res = await axios.put(`${API}/cms/order-status/${orderId}`, {
      status: "COMPLETED"
    }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_driver_for_order = async (token, orderId) => {

  try {
    const res = await axios.get(`${API}/cms/driver-for-order/${orderId}`, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }

}

export const api_send_delivery_notification = async (token, orderId) => {
  try {
    const res = await axios.put(`${API}/cms/order-status/${orderId}`, {
      status: ORDER_STATUS.IN_DELIVERY
    }, {
      headers: {
        'Authorization': token
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}
