import { flow } from 'mobx-state-tree'
import { api_get_all_locations } from '../../../queries/locations'
import { toJS } from 'mobx'

export const adminAnalyticsActions = (self) => ({
   
    async getLocations() {
        try {
            self.set('loading', true)

            const res = await api_get_all_locations(self.token)
            if (res.error) throw res

            let locations = [];
            res.data.map(user => {
                user.locations.map(location => {
                    locations.push(location)
                })
            })

            const ids = self.locationsFactory.addUpdateLocations(locations)

            self.set('locations', ids)
            self.set('loading', false)
            // self.set('locations', res.data)
            // self.set('loading', false)
            return res.data

        } catch (err) {
            self.set('loading', false)
            console.log(err)
        }
    },

    pushToLocations(location) {
        self.locations.push(location)
    },
    set(key, value) {
        self[key] = value
    }
})
