import { _throw } from "../../../utils/error-service";
import { getRoot } from "mobx-state-tree";
import { api_login, api_register, api_complete_registration, api_complete_password_reset } from "../../../queries/auth"
import { Store as Notify } from 'react-notifications-component';
import { ERRORS } from "../../../constants/strings"
import { toJS } from "mobx";
import { api_pause_orders, api_resume_orders } from "../../../queries/merchants";

export const authStoreActions = self => ({   
    async login() {
        self.set("error", false)
        self.set("loading", true)
        try {
          const res = await api_login({
            email: self.email,
            password: self.password,
          })
          
          if (res.error) throw (res)
          
          self.set("token", res.data.token)
          const userId = self.usersFactory.addUpdateUser(res.data.user)
          self.accountStore.set('user', userId)
          self.setRole()
          self.getInitData()
          self.set("loading", false)
          return res.data
          } catch (err) {
            console.log(err)
            self.set("error", true)
            self.set("loading", false)
            return _throw(err)
          }
      },
      setMerchant() {
        if (self.accountStore.user.role === "MERCHANT_MANAGER") {
          self.set("managingStore", self.accountStore.user.managingStore.id) 
        }
       },
       impersonateMerchant(merchantId) {
        self.rootStore.resetMerchantData()
        self.set("managingStore", merchantId)
       },
       setRole() {
        if (self.accountStore.isUserMerchant && self.isMerchantFullyOnboarded) {
          self.set("managingStore", self.accountStore.user.managingStore.id) 
        }
       },
       getInitData() {
        self.categoriesStore.getCategories()
        self.effectsStore.getEffects()
       },
      async register() {
        self.set("error", false)
        self.set("loading", true)
  
        try {
          const data = {
            user:{
              firstName: self.firstName,
              lastName: self.lastName,
              email: self.email,
              countryPrefix: "+" + self.countryPrefix,
              phone: self.phone.substring(self.countryPrefix.length),
            },
            password: self.password
          }
  
          const res = await api_register(data)
          if (res.error) throw (res)
  
          self.set("token", res.data.token)
          const userId = self.usersFactory.addUpdateUser(res.data.user)
          self.accountStore.set('user', userId)
  
          self.set("loading", false)
          
          return res.data
          } catch (err) {
            console.log(err)
            self.set("error", true)
            self.set("loading", false)
            return _throw(err)
          }
      },
      async completeRegistration() {
        self.set("error", false)
        self.set("loading", true)
        
        try {
          const data = {
            title: self.companyName,
            legalName: self.legalName,
            email: self.secondaryEmail,
            location:{
              address: self.address,
              lat: self.lat,
              lng: self.lng,
              city: self.city,
              state: self.state,
              country: "US",
              instructions: "",
              streetNumber: self.buildingNumber,
              street: self.streetName,
              post: self.post,
              floor: "",
              }
            }
  
          const res = await api_complete_registration(data, self.token)
          if (res.error) throw (res)
          const managingStoreId = self.merchantsFactory.addUpdateMerchant(res.data.data)
          self.accountStore.user.set('managingStore', managingStoreId)
          self.authStore.set('managingStore', managingStoreId)
          self.set("loading", false)
          return res.data
          } catch (err) {
            console.log(err)
            self.set("error", true)
            self.set("loading", false)
            return _throw(err)
          }
      },
      checkIfStillAuthorized(apiResponse) { 
        if (apiResponse.response.data.message === 'Unauthorized') {
          //self.resetStore()
          Notify.addNotification({
            title: "No Access!",
            message: "You have been signed out because you don't have the necessary permissions. Please contact your administrator.",
            type: "danger",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          return false
        } else {
          return true
        }
      },
      checkIfUserIsStillLegit() {
        if (self.accountStore?.user === null || self.accountStore?.user.userRole === null || self.accountStore?.user.hashId === null) {
          self.logout()
          return false
        } else {  
          return true
        }
      },
      clearInputs() {
        self.set('companyNameError', null)
        self.set('legalNameError', null)
        self.set('secondaryEmailError', null)
      },
      validCompanyName() {
        if (self.companyName === null || self.companyName.length < 1) self.set('companyNameError', ERRORS.NAME_ERROR)
        else self.set('companyNameError', null)
      },
      validLegalName() {
        if (self.legalName === null || self.legalName.length < 1) self.set('legalNameError', ERRORS.NAME_ERROR)
        else self.set('legalNameError', null)
      },
      validSecondaryEmail() {
        if (self.secondaryEmail === null) self.set('secondaryEmailError', ERRORS.NAME_ERROR)
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!regex.test(String(self.secondaryEmail).toLowerCase())) self.set('secondaryEmailError', ERRORS.EMAIL_ERROR)
        else self.set('secondaryEmailError', null)
      },
      async logout() {
        getRoot(self).reset()
      },
      resetStore() {
       // getRoot(self).reset()
      },
      getCountryCodeForDriver(){
          return "us"
      },
      async pauseOrders(mid=self.managingStore.id) {
        try {
          const res = await api_pause_orders(self.token, mid)
          if (res.error) throw res
    
    
          return res
        } catch (err) {
          console.log(err)
          return err
        }
      },
    
      async resumeOrders(mid=self.managingStore.id) {
        try {
          const res = await api_resume_orders(self.token, mid)
          if (res.error) throw res
    
    
          return res
        } catch (err) {
          console.log(err)
          return err
        }
      },

      async completePasswordReset(token) {
        try {
          const res = await api_complete_password_reset(token, self.password)
          if (res.error) throw res
    
    
          return res
        } catch (err) {
          console.log(err)
          return err
        }
      },
      set(key, value) {
        self[key] = value
      }
  })