import { types } from 'mobx-state-tree'
import * as views from './authStoreViews'
import * as actions from './authStoreActions'
import Location from '../../models/location/Location'
import Merchant from '../../models/merchant/Merchant'

const AuthStore = types
  .model('AuthStore', {
    token: types.maybeNull(types.string),
    managingStore:types.maybeNull(types.reference(Merchant)),

    email: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    confirmPassword: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    companyName: types.maybeNull(types.string),
    legalName: types.maybeNull(types.string),
    secondaryEmail: types.maybeNull(types.string),
    location: types.maybeNull(types.reference(Location)),
    secondaryEmailError: types.maybeNull(types.string),
    companyNameError: types.maybeNull(types.string),
    legalNameError: types.maybeNull(types.string),
    error: false,
    loading: false,

    phone: types.maybeNull(types.string),
    countryPrefix: types.maybeNull(types.string),


    lat: types.maybeNull(types.number),
    lng: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    buildingNumber: types.maybeNull(types.string),
    streetName: types.maybeNull(types.string),
    districtName: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    post: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    floor: types.maybeNull(types.string),
    instructions: types.maybeNull(types.string)
  })
  .views(views.authStoreViews)
  .actions(actions.authStoreActions)


export default AuthStore
