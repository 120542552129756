import { types } from 'mobx-state-tree';
import { deliveryZonesViews } from './DeliveryZonesViews';
import { deliveryZonesActions } from './DeliveryZonesActions';

const DeliveryZones = types
  .model('DeliveryZones', {
    id: types.maybeNull(types.number),
    merchantId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    minOrderSize: types.maybeNull(types.number),
    coordinates: types.array(types.array(types.number))
  })
  .views(deliveryZonesViews)
  .actions(deliveryZonesActions);

export default DeliveryZones;
