import { computed } from 'mobx';
import { getRoot } from 'mobx-state-tree';

export const adminAnalyticsViews = (self) => ({
    get token() {
        return getRoot(self).authStore.token
    },
    get locationsFactory() {
        return getRoot(self).locationsFactory
    }
})
