
export const deliveryZonesActions = self => ({
  update(dz) {
    self.set('name', dz.name)
    self.set('zipCode', dz.zipCode)
    self.set('minOrderSize', dz.minOrderSize)
    self.set('coordinates', dz.coordinates)
  },
  set(key, value) {
    self[key] = value
  }
})