import { localDateAndTime } from "../../../services/time-service"
import { formatCurrency } from "../../../services/currency-service"

export const orderViews = self => ({
  get totalAmountString() {
    if (self.totalAmount === null) return '$0.00'
    if (self.totalAmount !== null) return formatCurrency(self.totalAmount)
  },
  get showDeliveryFee() {
    if (self.deliveryFee === null) return '$0.00'
    if (self.deliveryFee !== null) return formatCurrency(self.deliveryFee)
  },
  get taxesAndOtherFess() {
    if (self.tax === null) return '$0.00'
    if (self.tax !== null) return formatCurrency((self.tax + self.tip))
  },
  get viewTax() {
    if (self.tax === null) return '$0.00'
    if (self.tax !== null) return formatCurrency((self.tax))
  },
  get viewTip() {
    if (self.tip === null) return '$0.00'
    if (self.tip !== null) return formatCurrency((self.tip))
  },
  get viewServiceFee() {
    if (self.serviceFee === null) return '$0.00'
    if (self.serviceFee !== null) return formatCurrency((self.serviceFee))
  },
  get showTotal() {
    if (self.totalAmount === null) return '$0.00'
    if (self.totalAmount !== null) return formatCurrency(self.totalAmount)
  }, 
  get showSubtotal() {

    if (self.subtotal === null) return '$0.00'
    if (self.subtotal !== null) {
      if (self.coupon?.id) {
        let discoutedPrice = self.subtotal - self.coupon?.value
        return formatCurrency(discoutedPrice)
      }
      return formatCurrency(self.subtotal)
    }
  },
  get orderCreatedAt() {
    if (self.orderDate === null) {
      return 'Unknown'
    } else { 
      return localDateAndTime(self.orderDate)
    }
  },
  get merchantName() {
    if (self.merchant?.title === null) {
      return 'Unknown'
    } else {
      return self.merchant?.title
    }
  },
  get isDriverPaid() {
    if (self.driverPaid === true) {
      return true
    } else {
      return false
    }
  },
  get isMerchantPaid() {
    if (self.merchantPaid === true) {
      return true
    } else {
      return false
    }
  },
  get isAmountSettled() {
    if (self.settled === true) {
      return true
    } else {
      return false
    }
  },
  get deliveryTypeLabel() {
    if (self.deliveryType !== null) {
      return self.deliveryType
    } else {
      return "DELIVERY"
    }
  },
  get customerAddress() {
    if (self.deliveryLocation === null ){
        return ''
    }
    else {
        return self.deliveryLocation?.address
    }
  },
  get showPlatformCommissionAmount() {
    if (self.platformCommissionAmount === null) return '$0.00'
    if (self.platformCommissionAmount !== null) return formatCurrency(self.platformCommissionAmount)
  },
  get showPlatformCommissionPercent() {
    if (self.platformCommissionPercent === null) return '0%'
    if (self.platformCommissionPercent !== null) return self.platformCommissionPercent + '%'
  },
})