import { types } from "mobx-state-tree"
import Asset from "../asset/Asset"
import Location from "../location/Location"
import OpeningHours from "../opening-hours/OpeningHours"
import * as views from './MerchantViews'
import * as actions from './MerchantActions'
import Category from "../category/Category"
import DeliveryZones from "../deliveryZones/DeliveryZones"

const Merchant = types
  .model('Merchant', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    legalName: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    newOrderStatus: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
    avgRating: types.optional((types.number), 0),
    deliveryFee: types.maybeNull(types.number),
    deliveryTime: types.maybeNull(types.number),
    deliveryRadius: types.maybeNull(types.number),
    commission: types.maybeNull(types.number),
    ordersCount: types.maybeNull(types.number),
    activeCategories: types.optional(
      types.array(types.reference(Category)), []
    ),
    minOrderValue: types.maybeNull(types.number),
    numberOfRatings: types.optional(
      (types.number), 0
    ),
    price: types.maybeNull(types.string),
    hero: types.maybeNull(Asset),
    location: types.maybeNull(types.reference(Location)),
    openingHours: types.optional(
      types.array(types.reference(OpeningHours)), []
    ),
    realTimeDeliveryEstimation: types.optional(types.boolean, true),
    deliveryZones: types.optional(
      types.array(DeliveryZones), []
    ),
    //deliveryCommision: types.maybeNull(types.number),
    //pickupCommision: types.maybeNull(types.number),

  })
  .views(views.merchantViews)
  .actions(actions.merchantActions)

export default Merchant